import "./contact.css";
import phone from "../assets/img/phone.png";
import discord from "../assets/img/discord.png";
import mail from "../assets/img/mail.png";
import {useContext, useRef} from "react";
import emailjs from '@emailjs/browser';
import {useState} from "react";
import {ThemeContext} from "../context";

const Contact = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const formRef = useRef();
    const [done, setDone] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_xmeuehm', 'template_42p12yr', formRef.current, 'a8F36vE21uFMTQh0U')
            .then((result) => {
                console.log(result.text);
                setDone(true);
            }, (error) => {
                console.log(error.text);
            });
    };
    return(
      <div className="contact-container">
          <div className= "c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                    <h1 className="c-title"> Let's <br/> Connect</h1>
                    <div className="c-info">
                        <img src={phone} alt="phone icon" className="c-icon"/>
                        +1 615 768 9742
                    </div>
                    <div className="c-info">
                        <img src={mail} alt="email icon" className="c-icon"/>
                        briannarforbes@gmail.com
                    </div>
                    <div className="c-info">
                        <img src={discord} alt="discord icon" className="c-icon"/>
                        bricodes
                    </div>
                </div>
                <div className="c-right">
                    <p className="c-desc">
                       <b>Would you like to know more? Get in touch.</b> Please fill out the form below.
                    </p>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input style ={{backgroundColor : darkMode && "#1f213f", color : darkMode && "white"}} className="input-row1" type="text" placeholder=" Name" name="user-name"/>
                        <input style ={{backgroundColor : darkMode && "#1f213f", color : darkMode && "white"}} className="input-row1" type="text" placeholder=" Email Address" name="user_email"/>
                        <input style ={{backgroundColor : darkMode && "#1f213f", color : darkMode && "white"}} type="text" placeholder=" Subject" name="user_subject"/>
                        <textarea style ={{backgroundColor : darkMode && "#1f213f", color : darkMode && "white"}} rows="5" placeholder=" Message" name="message" />
                        <br/>
                        <button>Submit</button>
                        {done && " Sent. Thank you!"}
                    </form>
                </div>
            </div>
      </div>
    )

}

export default Contact